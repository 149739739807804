/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        //ensure that doesnt shows
        $('.theModels').hide();

        var isBusy = false;
        $('.theAction a, .theClose').click(function () {
          doSlide();
        });

        function doSlide() {
          if (isBusy) return;
          isBusy = true;
          $('.theModels').slideToggle('normal', function () {
            isBusy = false;
          });
        }

        if ($('#menuContainer > div').length) {
          console.log('asd');
          $('#menuContainer > div').affix({
            offset: {
              top: $('#menuContainer > div').offset().top,
            }
          });
        }


        $('.servicio > a, .manejo > a').attr({"data-toggle": "tooltip", "data-placement": "bottom"});
        $('.servicio > a').attr("title", "Agenda tu servicio");
        $('.manejo > a').attr("title", "Prueba de manejo");

        $('[data-toggle="tooltip"]').tooltip();

        if ($('#hearted').length) {
          $('#hearted').append('<canvas></canvas>');
          var inverse = !!$('#hearted.inversed').length;
          var HeartsBackground = {
            heartHeight: 60,
            heartWidth: 64,
            hearts: [],
            heartImage: '/wp-content/themes/javo-sage-suzuki/dist/images/heart.svg',
            maxHearts: 14,
            minScale: 0.4,
            draw: function() {
              this.setCanvasSize();
              this.ctx.clearRect(0, 0, this.w, this.h);
              for (var i = 0; i < this.hearts.length; i++) {
                var heart = this.hearts[i];
                heart.image = new Image();
                heart.image.style.height = heart.height;
                heart.image.src = this.heartImage;
                this.ctx.globalAlpha = heart.opacity;
                this.ctx.drawImage (heart.image, heart.x, heart.y, heart.width, heart.height);
              }
              this.move();
            },
            move: function() {
              if (!inverse) {
                for(var b = 0; b < this.hearts.length; b++) {
                  var heart = this.hearts[b];
                  heart.y += heart.ys;
                  if(heart.y > this.h) {
                    heart.x = Math.random() * (this.w - this.heartWidth);
                    heart.y = -1 * this.heartHeight;
                  }
                }
              } else {
                for(var b = 0; b < this.hearts.length; b++) {
                  var heart = this.hearts[b];
                  heart.y -= heart.ys;
                  if(heart.y < (0 - this.heartHeight)) {
                    heart.x = Math.random() * (this.w - this.heartWidth);
                    heart.y = this.h + this.heartHeight;
                  }
                }
              }

            },
            setCanvasSize: function() {
              this.canvas.width = $('#hearted').width();
              this.canvas.height = $('#hearted').height();
              this.w = this.canvas.width;
              this.h = this.canvas.height;
            },
            initialize: function() {
              this.canvas = $('#hearted canvas')[0];

              if(!this.canvas.getContext) return;

              this.setCanvasSize();
              this.ctx = this.canvas.getContext('2d');

              for(var a = 0; a < this.maxHearts; a++) {
                var scale = (Math.random() * (1 - this.minScale)) + this.minScale;
                this.hearts.push({
                  x: Math.random() * (this.w - this.heartWidth),
                  y: Math.random() * this.h,
                  ys: Math.random() + 1,
                  height: scale * this.heartHeight,
                  width: scale * this.heartWidth,
                  opacity: scale
                });
              }

              setInterval($.proxy(this.draw, this), 30);
            }
          };

          $(document).ready(function(){
            HeartsBackground.initialize();
          });
        }

        // JavaScript to be fired on all pages
        var el_offset = parseInt(localStorage.el_offset) || parseInt('76');
        var hash = location.hash.replace(/^#/, '');
        var el_cuerpo = $('html,body');

        //  the good one
        $('a[href*=\\#]:not([href=\\#])').on('click', function(event){

          var href = event.currentTarget.href;
          href = href.split('#');

          if(href[0] === window.location.href) {

            event.preventDefault();

            var hash = $(this)[0].hash.replace(/^#/, '');

            if (hash && $('#' + hash).length) {
              el_cuerpo.animate({
                scrollTop: $('#' + hash).offset().top - el_offset
              }, 700, function () {
              });
            }

          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
